import React, { useState } from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

export default function Captcha() {
  const [cookie] = useCookies(["user"]);
  const [quantity, setQuantity] = useState(1);

  const BLOCKPRICE = 10;

  const handleClick = (e) => {
    if (e.target.id === "add-butt") {
      setQuantity(quantity + 1);
    } else if (e.target.id === "sub-butt") {
      setQuantity(Math.max(quantity - 1, 1));
    }
  };

  function checkLogin() {
    if (cookie.user == null) {
      toast("Please login and authenticate via Discord");
    } else {
      const link =
        config.STRIPECHECKOUT +
        "disc_id=" + cookie.user.disc_id +
        "&token=" + cookie.user.token +
        "&prod=captcha&quantity=" + quantity;

      window.open(link, "_blank");
    }
  }
  return (
    <div className="content">
      <Toaster
        toastOptions={{
          className: "",
          duration: 3000,
          style: {
            border: "2px solid #00cc00",
            padding: "16px",
            color: "#00cc00",
            background: "#181818",
          },
        }}
        containerStyle={{
          position: "absolute",
        }}
      />
      <div className="flex-container">
        <div className="single-item">
          <div className="left-set">
            <img src="./images/logo.svg" alt="" />
          </div>
          <div className="right-set">
            <div className="subname">Buy Captcha Credits</div>
            <div className="price">
              <span className="pricesmall">£ </span>
              {BLOCKPRICE}
              <span className="pricesmall"> per block</span> |{" "}
              <span className="pricesmall">£ </span>
              {quantity * BLOCKPRICE}
              <span className="pricesmall"> Total</span>
            </div>
            <div className="description">
              <p>
                <span className="captchatype">Recaptcha v2 Image Recognition</span>
                <br />
                <br />
                £1.86 - 1000 Captchas
              </p>
              <p>
                <span className="captchatype">FunCaptcha</span>
                <br />
                <br />
                £3.50 - 1000 Captchas
              </p>
            </div>
            <div className="stepper-input">
              <button id="sub-butt" className="stepperbtn-left" onClick={handleClick}>
                -
              </button>
              <input type="number" className="stepperinput-box" readOnly value={quantity} />
              <button id="add-butt" className="stepperbtn-right" onClick={handleClick}>
                +
              </button>
            </div>
            <button onClick={() => checkLogin()}>Buy Now</button>
          </div>
        </div>
      </div>
    </div>
  );
}
