import React from "react";
import { config } from "../constants";
import { useCookies } from "react-cookie";
import toast, { Toaster } from "react-hot-toast";

export default function Purchase() {
  const [cookie] = useCookies(["user"]);

  const checkLicStatus = (type) => {
    toast.dismiss(); // Clear existing toasts

    if (cookie.user == null) {
      return toast("Please login and authenticate via Discord");
    }

    if (type === "pro" && cookie.user.licenced === 3) {
      return toast("You already have a Pro Licence");
    }

    if (cookie.user.licenced === 1 && type !== "pro") {
      return toast("You already have an active licence");
    }

    if (cookie.user.stock === 0 && type === "new") {
      return toast("Out of stock");
    }

    fetch(
      config.STRIPECHECKOUT +
        "disc_id=" +
        cookie.user.disc_id +
        "&token=" +
        cookie.user.token +
        "&prod=" +
        type
    )
      .then((response) => response.text())
      .then((data) => window.location.replace(data));
  };

  function RenderPro() {
    return (
      <div className="single-item">
        <div className="left-set">
          <img src="./images/logo.svg" alt="" />
        </div>
        <div className="right-set">
          <div className="subname">Pro Licence</div>
          <div className="price">
            <span className="pricesmall">£ </span>379
            <span className="pricesmall"> /Lifetime Licence</span>
          </div>
          <div className="description">
            <p>
              No Monthly Fee
              <br />
              <br />
              Pro Features Unlocked
              <br />
              <br />
              Additional Standard Licence Included
            </p>
          </div>
          <button onClick={() => checkLicStatus("pro")}>Buy Now</button>
          <div className="subdescription">
            <p>
              By clicking Buy Now you agree to the terms found <a rel="noreferrer" target="_blank" href="https://hawkgen.co/terms" className="link">here</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function RenderNew() {
    return (
      <div className="single-item">
        <div className="left-set">
          <img src="./images/logo.svg" alt="" />
        </div>
        <div className="right-set">
          <div className="subname">Monthly Subscription</div>
          <div className="price">
            <span className="pricesmall">£ </span>20
            <span className="pricesmall"> /per month</span>
          </div>
          <div className="description">
            <p>
              Cancel and restart any time
              <br />
              <br />
              £5 restart fee
              <br />
              <br />
              One-off purchase fee of £79.99
            </p>
          </div>
          <button onClick={() => checkLicStatus("new")}>Buy Now</button>
          <div className="subdescription">
            <p>
              By clicking Buy Now you agree to the terms found <a rel="noreferrer" target="_blank" href="https://hawkgen.co/terms" className="link">here</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  function RenderReturning() {
    return (
      <div className="single-item">
        <div className="left-set">
          <img src="./images/logo.svg" alt="" />
        </div>
        <div className="right-set">
          <div className="subname">Returning Subscriber</div>
          <div className="price">
            <span className="pricesmall">£ </span>20
            <span className="pricesmall"> /per month</span>
          </div>
          <div className="description">
            <p>
              Cancel and restart any time
              <br />
              <br />
              £5 restart fee
              <br />
              <br />
              £10 Licence transfer fee
            </p>
          </div>
          <button onClick={() => checkLicStatus("returning")}>Buy Now</button>
          <div className="subdescription">
            <p>
              By clicking Buy Now you agree to the terms found <a rel="noreferrer" target="_blank" href="https://hawkgen.co/terms" className="link">here</a>
            </p>
          </div>
        </div>
      </div>
    );
  }

  let showNew = true;
  if (cookie.user != null && cookie.user.purchased === 1) showNew = false;

  return (
    <>
      <Toaster
        toastOptions={{
          className: "",
          duration: 3000,
          style: {
            border: "2px solid #00cc00",
            padding: "16px",
            color: "#00cc00",
            background: "#181818",
          },
        }}
        containerStyle={{
          position: "absolute",
        }}
      />
      <div className="content">
        <div className="flex-container">
          {showNew ? <RenderNew /> : <RenderReturning />}
          <RenderPro />
        </div>
      </div>
    </>
  );
}
