import React from "react";
import { useLocation } from "react-router-dom";
import { config } from "../constants";

export default function Checkout() {
  const { search } = useLocation();
  let queryString = search;

  let params = {
    success: null,
    session_id: null,
    prod: null
  };

  if (queryString !== "") {
    let resArray = queryString.split("?")[1].split("&");
    resArray.forEach((element) => {
      let eles = element.split("=");

      if (eles[0] === "success") {
        params.success = eles[1] === "1" ? true : false;
      }

      if (eles[0] === "session_id") {
        params.session_id = eles[1];
      }

      if (eles[0] === "prod") {
        params.prod = eles[1];
      }
    });

    if (params.session_id != null && params.prod == null) {
      const url = config.HAWKUPDATENEW + params.session_id;
      fetch(url);
    }

    else if (params.session_id != null && params.prod === "captcha") {
      const url = config.HAWKUPDATENEW + params.session_id + "&prod=captcha";
      fetch(url);
    }

    else if (params.session_id != null && params.prod === "pro") {
      const url = config.HAWKUPDATENEW + params.session_id + "&prod=pro";
      fetch(url);
    }

  }

  function renderMsg() {
    if (params.success === true) {
      return (
        <>
          Thank you for your purchase
          <br />
          <br /> Your receipt reference is:<br/>
          <span className="sessionid">{params.session_id}</span>{" "}
        </>
      );
    } else if (params.success === false) {
      return (
        <>
          Your purchase did not complete
          <br />
          <br />
          If you have any questions or issues
        </>
      );
    } else if (params.success === null) {
      return <div></div>;
    }
  }

  return (
    <div className="content">
      <div className="checkoutContainer">
        {renderMsg()}
        <br />
        <br />
        Please join the Server below
        <br/>
        <a
          rel="noreferrer"
          target="_blank"
          href="https://discord.gg/X45wrXy3qQ"
        >
          <img className="checkoutImage" src="/images/discord.svg" alt="discord" />
        </a>
      </div>
    </div>
  );
}
